export const LINK_CATEGORIES = {
    code: [
        { name: 'github', display: 'Github' },
        { name: 'gitlab', display: 'Gitlab' }
    ],
    data: [
        { name: 'geo', display: 'Gene Expression Omnibus' },
        { name: 'dbGap', display: 'The Database of Genotypes and Phenotypes' },
        { name: 'kaggle', display: 'Kaggle' },
        { name: 'dryad', display: 'Dryad' },
        { name: 'empiar', display: 'Electron Microscopy Public Image Archive' },
        { name: 'gigaDb', display: 'GIGADB' },
        { name: 'zenodo', display: 'Zenodo' },
        { name: 'ega', display: 'European Genome-phenome Archive' },
        { name: 'xlsx', display: 'Excel Sheets' },
        { name: 'csv', display: 'Comma-separated Value Files' },
        { name: 'proteinDataBank', display: 'Protein Data Bank' }
    ],
    containers: [
        { name: 'codeOcean', display: 'Code Ocean Capsules' },
        { name: 'colab', display: 'Google Colab Capsules' }
    ],
    results: [
        { name: 'gsea', display: 'Gene Set Enrichment Analysis' },
        { name: 'figshare', display: 'Figshare' }
    ],
    trials: [{ name: 'clinicalTrial', display: 'Clinical Trials' }],
    miscellaneous: [
        { name: 'IEEE', display: 'Institute of Electrical and Electronics Engineers' },
        { name: 'pdf', display: 'PDFs' },
        { name: 'docx', display: 'Word Documents' },
        { name: 'zip', display: 'Compressed Files' }
    ]
};
